<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card title="images">
      <validation-observer ref="simpleRules">
        <b-col cols="12">
          <b-row>
            <b-col sm="12">
              <div>
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in itemData.images"
                  :id="item.id"
                  :key="item.id"
                >
                  <!-- Image Reapeted -->
                  <b-col md="4">
                    <b-form-group label="Image" label-for="image">
                      <validation-provider
                        #default="{ errors }"
                        name="image"
                        rules="required"
                      >
                        <b-form-file
                          type="file"
                          :name="'image' + index"
                          v-model="upImage[index].file"
                          @change="uploadImage($event, index)"
                          :state="errors.length > 0 ? false : null"
                          :id="'image' + index"
                          accept="image/*"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="image description"
                        rules="required"
                      >
                        <b-form-input
                          :id="'imagedescription' + index"
                          v-model="itemData.images[index].description"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-if="index > 0"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

// import axios from '@/libs/axios'
export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      upImage: [
        {
          file: [],
        },
      ],
      showDismissibleAlert: false,
      errors_back: [],
      test_result: '',
      itemData: {
        images: [
          {
            file: '',
            description: '',
          },
        ],
      },
      required,
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  methods: {
    // submuit add
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let img in this.itemData.images) {
            if (this.itemData.images[img].file.includes(',')) {
              this.itemData.images[img].file = this.itemData.images[img].file
                .split(',')
                .map((item) => item.trim())
              this.itemData.images[img].file = this.itemData.images[img].file[1]
            }
          }
          axios
            .post('items-guide/' + this.id + '/images/store', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // encode images
    uploadImage(event, index) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        this.itemData.images[index].file = []
        return
      } else {
        this.createImage(files[0], index)
      }
    },
    createImage(file, ind) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.images[ind].file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // repeated function
    repeateAgain() {
      this.itemData.images.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.upImage.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      if (this.itemData.images.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.images.splice(index, 1)
        this.upImage.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss">
</style>