export const heightTransition = {
    /*

      HowTo:
        1. Add dynamic style to element and set style as `trHeight`
        2. Set transition speed using `transition: 0.35s height;` <= you can use appropriate value;
        3. Optionally you can set `overflow: hidden;` to hide element overflow while height is animated.
        4. Set initial height using `trSetHeight` before any operation. [mounted hook is recommended - You can use `ref` for dynamic contents]
        5. Toggle height using height operations 🍻
        6. Toggle usage of $nextTick for height operations is any issue occur [experimental] 🔬

    */

    data() {
        return {
            trHeight: null,
            trHeight2: null,
            trHeight3: null,
            trHeight4: null,
        }
    },
    methods: {
        trAddHeight(val) {
            // Add height to existing height
            // Usage: Where new element is append or more height is added (e.g. list append)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
            this.trHeight = `${heightValue + Number(val)}px`
        },
        trTrimHeight(val) {
            // Remove height from existing height
            // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
            this.trHeight = `${heightValue - Number(val)}px`
        },
        trSetHeight(val) {
            // Set height
            // Usage: Mostly for assigning initial value from mounted hook

            /* Assumes:
             - Height is not assigned and what to assign for add/remove operation
             - What to set height at something for odd usage
             - Incoming value is valid number in `Number` or `String`
            */
            if (val === null) this.trHeight = 'auto'
            else this.trHeight = `${Number(val)}px`
        },


        trAddHeight2(val) {
            // Add height to existing height
            // Usage: Where new element is append or more height is added (e.g. list append)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue2 = Number(this.trHeight2.substring(0, this.trHeight2.length - 2))
            this.trHeight2 = `${heightValue2 + Number(val)}px`
        },
        trTrimHeight2(val) {
            // Remove height from existing height
            // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue2 = Number(this.trHeight2.substring(0, this.trHeight2.length - 2))
            this.trHeight2 = `${heightValue2 - Number(val)}px`
        },
        trSetHeight2(val) {
            // Set height
            // Usage: Mostly for assigning initial value from mounted hook

            /* Assumes:
             - Height is not assigned and what to assign for add/remove operation
             - What to set height at something for odd usage
             - Incoming value is valid number in `Number` or `String`
            */
            if (val === null) this.trHeight2 = 'auto'
            else this.trHeight2 = `${Number(val)}px`
        },



        trAddHeight3(val) {
            // Add height to existing height
            // Usage: Where new element is append or more height is added (e.g. list append)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue3 = Number(this.trHeight3.substring(0, this.trHeight3.length - 2))
            this.trHeight3 = `${heightValue3 + Number(val)}px`
        },
        trTrimHeight3(val) {
            // Remove height from existing height
            // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue3 = Number(this.trHeight3.substring(0, this.trHeight3.length - 2))
            this.trHeight3 = `${heightValue3 - Number(val)}px`
        },
        trSetHeight3(val) {
            // Set height
            // Usage: Mostly for assigning initial value from mounted hook

            /* Assumes:
             - Height is not assigned and what to assign for add/remove operation
             - What to set height at something for odd usage
             - Incoming value is valid number in `Number` or `String`
            */
            if (val === null) this.trHeight3 = 'auto'
            else this.trHeight3 = `${Number(val)}px`
        },


        trAddHeight4(val) {
            // Add height to existing height
            // Usage: Where new element is append or more height is added (e.g. list append)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue4 = Number(this.trHeight4.substring(0, this.trHeight4.length - 2))
            this.trHeight4 = `${heightValue4 + Number(val)}px`
        },
        trTrimHeight4(val) {
            // Remove height from existing height
            // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

            /* Assumes:
             - Height is assigned and is `String`
             - Incoming value is valid number in `Number` or `String`
            */
            const heightValue4 = Number(this.trHeight4.substring(0, this.trHeight4.length - 2))
            this.trHeight4 = `${heightValue4 - Number(val)}px`
        },
        trSetHeight4(val) {
            // Set height
            // Usage: Mostly for assigning initial value from mounted hook

            /* Assumes:
             - Height is not assigned and what to assign for add/remove operation
             - What to set height at something for odd usage
             - Incoming value is valid number in `Number` or `String`
            */
            if (val === null) this.trHeight4 = 'auto'
            else this.trHeight4 = `${Number(val)}px`
        },
    },
}

// Ignore below for now. We will remove it when we add more transition in future.
export const _ = null